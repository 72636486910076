<template>
  <div
    v-if="headerSettings.visible"
    :class="['menu-wrapper', { specific: specificClass }]"
    :style="{ backgroundColor: headerSettings.headerBg }"
  >
    <div class="menu-items">
      <ul>
        <li v-for="(item, index) in menuItems" :key="index">
          <router-link
            :style="linkStyles"
            :class="{ active: checkRoute(item) }"
            :to="item.link"
          >
            {{ item.text }}
          </router-link>
        </li>
      </ul>
    </div>

    <Button
      :key="loopKey"
      :bg-color="headerSettings.buttonBg"
      :color="headerSettings.buttonColor"
      :filter="headerSettings.filter"
      :opacity="headerSettings.opacity"
      :label="labels.login_register"
      icon="custom-user-2"
      class="login-register-link"
    />
  </div>
</template>

<script>
import Button from "@/components/Buttons/Button.vue";
const colors = require("/colors.config.json");
export default {
  name: "HeaderMenu",
  components: {
    Button,
  },
  data() {
    return {
      loopKey: Date.now(),
      labels: {
        our_actions: "Unsere Aktionen",
        module: "Module",
        faq: "FAQ",
        team: "Team",
        newsletter: "Newsletter",
        partner_program: "Partnerprogramm",
        climate_protection: "Klimaschutz",
        about_us: "Über uns",
        login_register: "Login / Registrieren",
      },
    };
  },
  computed: {
    menuItems() {
      return [
        { text: this.labels.our_actions, link: "/our-actions" },
        {
          text: this.labels.module,
          link: {
            name: "package-overview",
            params: { state: "Nordrhein-Westfalen" },
          },
        },
        { text: this.labels.faq, link: "/faq" },
        { text: this.labels.team, link: "/team" },
        { text: this.labels.newsletter, link: "/newsletter" },
        { text: this.labels.partner_program, link: "/partner-program" },
        {
          text: this.labels.climate_protection,
          link: "https://climate.stripe.com/3zuz56",
        },
        { text: this.labels.about_us, link: "/about-us" },
      ];
    },
    headerSettings() {
      let settings = {
        visible: false,
        linksOnHover: false,
        headerBg: `${colors.navy}`,
        buttonBg: `${colors.white}`,
        buttonColor: `${colors.navy}`,
        filter: "brightness(100%)",
        opacity: "0.8",
      };
      if (
        [
          "home",
          "our-actions",
          "faq",
          "team",
          "newsletter",
          "partner-program",
          "about-us",
          "page-not-found",
        ].includes(this.$route.name)
      ) {
        settings.visible = true;
      }
      if (
        ["our-actions", "home", "newsletter", "page-not-found"].includes(
          this.$route.name
        )
      ) {
        settings.linksOnHover = true;
        settings.headerBg = "transparent";
        settings.buttonBg = `${colors.navy}`;
        settings.buttonColor = `${colors.white}`;
        settings.filter = "brightness(80%)";
        settings.opacity = "1";
      }
      return settings;
    },
    linkStyles() {
      let res = {
        "--link-color": ["page-not-found"].includes(this.$route.name)
          ? "#292929"
          : `${colors.white}`,
        "--link-active-color":
          this.headerSettings.headerBg !== "transparent"
            ? `${colors.white}`
            : `${colors.navy}`,
      };

      return res;
    },
    specificClass() {
      let output = false;
      if (["our-actions", "home", "newsletter"].includes(this.$route.name))
        output = true;
      return output;
    },
  },
  methods: {
    checkRoute(item) {
      if (this.$route.name === "page-not-found") return false;
      if (this.$route.name === this.$router.resolve(item.link).route.name)
        return true;

      return false;
    },
  },
  watch: {
    headerSettings: {
      deep: true,
      handler() {
        this.loopKey = Date.now();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.menu-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  position: static;

  @media screen and (max-width: 991px) {
    display: none;
  }

  @media (min-width: 1200px) {
    padding: 15px 45px;
  }

  @media (min-width: 1200px) and (max-width: 1299px) {
    padding: 15px;
  }

  @media (min-width: 1600px) {
    padding: 15px 85px;
  }

  @media (min-width: 1600px) and (max-width: 1700px) {
    padding: 15px 45px;
  }

  &.specific {
    padding-top: 46px;

    @media (min-width: 992px) {
      padding-top: 30px;
    }

    @media (min-width: 1600px) {
      padding: 46px 45px 15px;
    }

    @media (min-width: 1700px) {
      justify-content: flex-end;
      padding: 46px 100px 15px;
    }

    .menu-items {
      a {
        &.active,
        &:hover {
          color: $focus_color;
        }
      }
    }
  }

  .menu-items {
    margin-left: 0px;
    margin-right: 0px;
    align-items: center;
    ul {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0;
      margin: 0;
      background: none;
      list-style: none;
      font-size: 16px;

      li {
        margin-top: 15px;
        margin-bottom: 15px;
        position: relative;

        a {
          color: var(--link-color);
          font-weight: 500;
          font-size: 14px;
          text-decoration: none;
          padding: 0 15px;
          display: flex;
          word-break: break-word;

          @media (min-width: 992px) and (max-width: 1100px) {
            padding: 0 10px;
          }

          @media (min-width: 1200px) and (max-width: 1399px) {
            padding: 0 10px;
          }

          @media (min-width: 1600px) {
            padding: 0 25px;
          }

          &.active,
          &:hover {
            color: var(--link-active-color);
          }
        }

        &:first-child {
          a {
            padding-left: 0;
          }
        }
      }
    }
  }

  .login-register-link {
    margin-left: 15px;
    padding: 11px 30px;

    @media (min-width: 992px) and (max-width: 1100px) {
      padding: 11px 15px;
    }

    @media (min-width: 1200px) and (max-width: 1250px) {
      margin-left: 0px;
    }

    @media (min-width: 1200px) and (max-width: 1399px) {
      padding: 11px 15px;
    }

    @media (min-width: 1700px) {
      margin-left: 25px;
    }
  }
}
</style>
